import { t } from 'i18next';
import _ from 'lodash';
import { countries } from './../Distributors/freedomDB/db';
import { typesOfCreditCard } from '../FreedomConstants/freedomConstants';
import moment from 'moment';

import { basicProductPlusbg, basicProductbg, eliteProductbg, supremeProductbg, basicProductbgLight, basicProductPlusbgLight, supremeProductbgLight, eliteProductbgLight } from '../url/SvgLinks';
import { ColorModeContext } from '../store';
import { useContext } from 'react';
import { maxWidth } from '@mui/system';

export const isValidEmail = (email) => {
  if (email.toString().toLowerCase().includes('@')) return true;
  else return false;
};

//import all country flags from countryImages folder
export const countryFlags = () => {
  const context = require.context('./../Images/countryImages', true, /.png$/);

  const flag = {};
  context.keys().forEach((key) => {
    const countryCode = key
      .split('./')
      .pop() // remove the first 2 characters
      .substring(0, key.length - 6); // remove the file extension
    flag[countryCode] = context(key);
  });

  return flag;
};

export const emailSearchSenitization = (SearchEmail) => {
  let searchText = SearchEmail.toLowerCase();

  if (SearchEmail.charAt(0) === '+') {
    searchText = SearchEmail.substring(1);
  } else if (SearchEmail.charAt(0) === '0' || SearchEmail.charAt(0) === '00') {
    searchText = SearchEmail.substring(1);
  }

  return searchText;
};

export const startsWithNumber = (str) => {
  return /^\d/.test(str);
};

export const arrayHead = (array) => {
  let headArray = [];
  array.forEach((cell) => {
    headArray.push(cell.label);
  });
  return [[...headArray]];
};

export const next_5days = () => {
  const today = new Date();
  const tomorrow = new Date();
  return new Date(tomorrow.setDate(today.getDate() + 5));
};

//get local date and time
export const currentTime24hr = () => {
  return new Date().toLocaleTimeString('en-US', { hour12: false });
};

export const currentDate = () => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };
  return new Date().toLocaleDateString(undefined, options);
};

export const getAge = (birthDate) => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

// Convert Otp value to String

export const getOtpValue = (otp1, otp2, otp3, otp4, otp5, otp6) => {
  return `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`;
};

export const getFullNameOfCountry = (ISO2_Code) => {
  return countries.filter((item) => item.ISO2 === ISO2_Code)[0]?.Name;
};

export const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const difference = Date.now() - birthDate.getTime();
  const age = new Date(difference);

  return Math.abs(age.getUTCFullYear() - 1970);
};

export const capitalizeFullName = (fullName) => {
  // Trim leading/trailing white space
  if (fullName === undefined || fullName === null || fullName === '') return;
  fullName = fullName.trim();

  // Split into an array of words
  let words;
  if (fullName.includes('  ')) {
    words = fullName.split('  ');
  } else {
    words = fullName.split(' ');
  }

  // Determine first and last name
  let firstName, lastName, middleName;
  if (words.length === 2) {
    firstName = words[0];
    lastName = words[1];
  } else if (words.length === 3) {
    firstName = words[0];
    lastName = words[1];
    middleName = words[2];
  } else {
    firstName = words.slice(0, -1).join(' ');
    lastName = words[words.length - 1];
  }

  // Capitalize first letter of each name
  firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
  if (middleName !== undefined) {
    middleName = middleName?.charAt(0).toUpperCase() + middleName?.slice(1);
  }

  // Concatenate first and last name
  let separatedName = '';
  if (middleName !== undefined) {
    separatedName = `${firstName} ${lastName} ${middleName}`;
  } else {
    separatedName = `${firstName} ${lastName}`;
  }
  return separatedName;
  // return str?.slice(0,1)?.toUpperCase()+str?.slice(1)
  // return _.startCase(str)
};

export const capitalize = (str) => {
  return str?.slice(0, 1)?.toUpperCase() + str?.slice(1);
};

export const getMessage = (error) => {
  if (error?.response === undefined) {
    return `${t(`Please check your internet connection`)}`;
  }
  if (error?.response?.status === 404) {
    return `${t(`Resource Not Found`)}`;
  }
  if (error?.response?.status === 401) {
    return `${t(`Unauthorized, please refresh and try again`)}`;
  }
  if (error?.response === 503) {
    return `${t(`Service Unavailable`)}`;
  }
  // else {
  // setErrorMessage(error?.response?.data);
  // setIsError(true);
  return `${t(`${error?.response?.status}: Something went Wrong`)}`;
};

export const getNoDataTxt = () => {
  return `Currently There Is No Data Available To Display.`;
};

export const FixedAfterTwoDigit = (value) => {
  if (value === undefined) return;
  return value.toFixed(2);
};
export const FixedAfterTwoDigit1 = (value, minimumdigit) => {
  const number = parseFloat(_.replace(value, /,/g, ''));
  if (minimumdigit === 0) return number.toLocaleString();
  return number.toLocaleString(undefined, {
    minimumFractionDigits: minimumdigit || 2,
    maximumFractionDigits: minimumdigit || 2
  });
};

export const AmountAfterFeeAdded = (amount, transactionFee) => {
  if (amount === 0 || amount === '') {
    return;
  }
  var formattedValue = amount.toLocaleString().split('.')[0];
  formattedValue = formattedValue.toLocaleString().replace(/\D/g, '');

  formattedValue = +formattedValue + +transactionFee;
  return formattedValue;
};

export const devConsoleLog = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
};

export const RemoveCommaAndDigit = (value) => {
  const withoutComma = value.replace(',', '');

  return parseInt(withoutComma).toString();
};

//get creditCard text color
export const creditCardTextColor = (cardName) => {
  switch (cardName) {
    case typesOfCreditCard.visa:
      return {
        color: '#ffffff'
      };
    case typesOfCreditCard.master:
      return {
        color: '#000000'
      };
    default:
      return 'Unknown step';
  }
};

export const nextVaule = (number) => {
  if (number <= 0) return 100;
  return Math.ceil(number / 100) * 100;
};
export const previousVaule = (number) => {
  if (number >= 0) return -100;
  return Math.floor(number / 100) * 100;
};

//Linear Gradient

export const linearGradientToColor = (scale, color1, percentage1, color2, percentage2) => {
  return `linear-gradient(${scale || '180deg'}, ${color1} ${percentage1 || '0%'}, ${color2} ${percentage2 || '100%'})`;
};

const formatCurrency = (value, locale) => {
  const getUserLocale = () => {
    const defaultLocale = 'en-US'; // Set a default locale if none is detected
    return (navigator.language || defaultLocale).replace('_', '-');
  };

  const userLocale = locale || getUserLocale();

  return value.toLocaleString(userLocale, {
    minimumFractionDigits: 2, // Show at least two digits after the decimal point
    maximumFractionDigits: 2 // Show at most two digits after the decimal point
  });
};

export default formatCurrency;

/**
 * get week number
 *
 * */

export const getWeekNumbersArray = (year) => {
  const weeksArray = [];

  // Loop through the 52 weeks of the year
  for (let weekNumber = 1; weekNumber <= 52; weekNumber++) {
    // Create a date object for the first day of the week
    const date = new Date(year, 0, (weekNumber - 1) * 7 + 1);

    // Format the date to 'YYYY-MM-DD' string
    const formattedDate = date.toISOString().slice(0, 10);

    // Add the week number and createdOn to the array
    weeksArray.push({
      Week: weekNumber,
      createdOn: formattedDate,
      Internal: 0,
      Trading: 0,
      Income: 0
    });
  }

  return weeksArray;
};

/**
 * get youtube video link for onboard
 *
 * */

export const videoUrlForOnboard = (userType, activeStep) => {
  if (activeStep >= 0) {
    if (userType === 2 || userType === 5) {
      switch (activeStep) {
        case 0:
          return 'https://youtu.be/yafLCWcu7q8?si=Vqfh1kzCCU7tlWPr&t=229';
        case 1:
          return 'https://youtu.be/yafLCWcu7q8?si=Vqfh1kzCCU7tlWPr&t=341';
        case 2:
          return 'https://youtu.be/yafLCWcu7q8?si=Vqfh1kzCCU7tlWPr&t=468';
        default:
          return 'https://youtu.be/yafLCWcu7q8?si=Vqfh1kzCCU7tlWPr&t=229';
      }
    } else if (userType === 1) {
      switch (activeStep) {
        case 0:
          return 'https://youtu.be/eP3IyJAMb8U?si=J-JXZWhf36tiz7cX&t=209';
        case 1:
          return 'https://youtu.be/eP3IyJAMb8U?si=ShJ-LzWhD5JuRc7M&t=323';
        case 2:
          return 'https://youtu.be/eP3IyJAMb8U?si=5LfHImyOh49nvUsx&t=451';
        default:
          return 'https://youtu.be/eP3IyJAMb8U?si=J-JXZWhf36tiz7cX&t=209';
      }
    } else if (userType === 3) {
      switch (activeStep) {
        case 0:
          return 'https://youtu.be/HmH2zHDmpOo?si=xjMpW2loU7OlTTwq&t=219';
        case 1:
          return 'https://youtu.be/HmH2zHDmpOo?si=nOYTLEldY2TCtw-D&t=333';
        case 2:
          return 'https://youtu.be/HmH2zHDmpOo?si=Vq5oBSNbi7I54Gp5&t=461';
        default:
          return 'https://youtu.be/HmH2zHDmpOo?si=xjMpW2loU7OlTTwq&t=219';
      }
    }
  } else {
    if (userType === 2) {
      return 'https://www.youtube.com/embed/yafLCWcu7q8';
    } else if (userType === 1) {
      return 'https://www.youtube.com/embed/eP3IyJAMb8U';
    } else if (userType === 3) {
      return 'https://www.youtube.com/embed/HmH2zHDmpOo';
    }else if (userType === 5) {
      return 'https://www.youtube.com/embed/yafLCWcu7q8';
    }
  }
};

/**
 * week dropdown data
 */

export const weekDropDownData = (currentWeekNumber, selectedYear) => {
  let last7days;
  const weeksOfYear = [];
  const numWeeks = selectedYear === moment().format('YYYY') ? currentWeekNumber : moment().year(selectedYear).isoWeeksInYear();

  for (let i = 1; i <= numWeeks; i++) {
    const startDate = moment().year(selectedYear).isoWeek(i).startOf('week');
    const endDate = moment().year(selectedYear).isoWeek(i).endOf('week');
    weeksOfYear.push({
      id: i,
      lable: 'Week ' + i,
      startDate: startDate.format('MM/DD/YYYY'),
      endDate: endDate.format('MM/DD/YYYY'),
      year: selectedYear
    });
  }
  last7days = [
    ...weeksOfYear,
    {
      id: 'last7days',
      lable: 'Last 7 Days',
      startDate: moment().year(selectedYear).subtract(7, 'd').format('YYYY-MM-DD'),
      endDate: moment().year(selectedYear).format('YYYY-MM-DD'),
      year: selectedYear
    }
  ];

  return last7days;
};
/**
 * function for API error responses
 */

export const apiErrorResponses = (error) => {
  if (error?.response === undefined) return 'Please check your internet connection';
  switch (error?.response?.status) {
    case 404:
      return 'Resource Not Found';
    case 401:
      return 'Unauthorized, please refresh and try again';
    case 503:
      return 'Service Unavailable';
    default:
      return `${error?.response?.status}: Something went Wrong`;
  }
};

/**
 * pure functions to calcualte graphs initial and final date
 */

export const pureFunctions = {
  last7daysStartDate: () => moment().subtract(6, 'd').format('YYYY-MM-DD'),
  last7daysEndDate: () => moment().format('YYYY-MM-DD'),
  selectedYear: () => moment().format('YYYY'),
  currentIsoWeekNo: () => moment().isoWeek(),
  currentMonthNo: () => moment().month(),
  currentYearLastDate: () => moment().endOf('month').format('YYYY-MM-DD'),
  currentMonth: () => moment().format('MMMM'),
  previousYearLastMonthDate: () => moment(pureFunctions.currentYearLastDate()).subtract(1, 'years').format('YYYY-MM-DD')
};

/**
 * invitation followup days function
 */

export const followUpdays = () => {
  let days = [];
  for (let i = 0; i <= 5; i++) {
    days[i] = {
      label: new Date(new Date().setDate(new Date().getDate() + i)).toISOString(),
      title: new Date(new Date().setDate(new Date().getDate() + i)).toDateString()
    };
  }
  return days;
};

/**
 * status chips
 */

export const getStatusLabel = (item) => {
  switch (item.status) {
    case 1:
      return 'Pending';
    case 2:
      return 'Onboarding';
    case 3:
      return 'Leg not set';
    case 6:
      return 'Approval Pending';
    case 7:
      return 'Leg not set';
    case 8:
      return 'Compliance Pending';

    default:
      return 'Empty';
  }
};

/****************
 * * get product card background images and border
 *
 * */

export const ProductbgImageandColor = (productItem) => {
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);

  if (productItem.productName == 'Basic' || productItem.productName == 'Advance' || productItem.productName == 'Pro') {
    if (mode === 'light') {
      return { bg: basicProductbgLight, borderColor: productItem?.webLightBorderColor };
    } else {
      return { bg: basicProductbg, borderColor: productItem?.webDarkBorderColor };
    }
  } else if (productItem.productName == 'Basic+' || productItem.productName == 'Advance+' || productItem.productName == 'Pro+') {
    if (mode === 'light') {
      return { bg: basicProductPlusbgLight, borderColor: productItem?.webLightBorderColor };
    } else {
      return { bg: basicProductPlusbg, borderColor: productItem?.webDarkBorderColor };
    }
  } else if (productItem.productName == 'Supreme') {
    if (mode === 'light') {
      return { bg: supremeProductbgLight, borderColor: productItem?.webLightBorderColor };
    } else {
      return { bg: supremeProductbg, borderColor: productItem?.webDarkBorderColor };
    }
  } else if (productItem.productName == 'Elite') {
    if (mode === 'light') {
      return { bg: eliteProductbgLight, borderColor: productItem?.webLightBorderColor };
    } else {
      return { bg: eliteProductbg, borderColor: productItem?.webDarkBorderColor };
    }
  } else {
    if (mode === 'light') {
      return { bg: eliteProductbgLight, borderColor: productItem?.webLightBorderColor };
    } else {
      return { bg: eliteProductbg, borderColor: productItem?.webDarkBorderColor };
    }
  }
};

export const getContainerStyle = (mode, theme, productItem) => {
  return {
    backgroundImage: `url(${ mode === "light" ? productItem?.webLightUrl : productItem?.webDarkUrl})`,
    // backgroundImage: `url(${ProductbgImageandColor(productItem).bg})`,
    width: '100%',
    height: 'auto',
    maxWidth: '1333px',
    borderTopLeftRadius: '63.38px',
    borderBottomRightRadius: '63.38px',
    border: `2px solid ${mode === "light" ? productItem?.webLightBorderColor : productItem?.webDarkBorderColor}`,
    // padding: '10px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xl')]: {
      borderBottomRightRadius: '20px',
      borderTopLeftRadius: '20px',
      backgroundImage: `url(${mode === "light" ? productItem?.mobileLightUrl : productItem?.mobileDarkUrl})`,
      height: '450px',
      height: '450px',
      width: '320px'
    },
    [theme.breakpoints.up('xll')]: {
      borderBottomRightRadius: '40px',
      borderTopLeftRadius: '40px'
    }
  };
};
// export const getContainerStyle = (mode, theme, productItem) => {
//   return {
//     backgroundImage: `url(${mode === 'light' ? productItem?.webLightUrl : productItem?.webDarkUrl})`,
//     // backgroundImage: `url(${ProductbgImageandColor(productItem).bg})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     height: 'auto',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     flexDirection: 'column',
//     width: '100%',
//     borderBottomRightRadius: '30px',
//     borderTopLeftRadius: '30px',
//     border: `2px solid ${mode === 'light' ? productItem?.webLightBorderColor : productItem?.webDarkBorderColor}`,
//     padding: '10px',
//     [theme.breakpoints.down('sm')]: {
//       borderBottomRightRadius: '20px',
//       borderTopLeftRadius: '20px'
//     },
//     [theme.breakpoints.up('xll')]: {
//       borderBottomRightRadius: '40px',
//       borderTopLeftRadius: '40px'
//     }

//   };
// };

/* Get Quarter Month */

export const getLastDateOfQuarter = (year) => {
  const currentQuarter = moment().quarter(); // Get the current quarter
  console.log('currentQuarter', currentQuarter);
  const startMonth = (currentQuarter - 1) * 3 + 1; // Calculate the start month of the quarter
  const endMonth = startMonth + 2; // Calculate the end month of the quarter
  const startDate = moment(`${year}-${startMonth}-01`, 'YYYY-MM-DD');
  const endDate = moment(startDate).endOf('quarter');
  return endDate.format('YYYY-MM-DD');
};

// calculate the productTypeId based on dropdown
export const getProductTypeId = (productTypeId) => {
  if(!productTypeId) return
  switch (productTypeId) {
    case 1:
      return 0
    case 2:
      return 1
    case 3:
      return 2
  
    default:
      return 0;
  }
}

/**
 * prod env
 */

export const isProdMode = () => {
  return process.env.REACT_APP_ENV === 'test';
}